import React, {useEffect, useState, useRef} from 'react';
import '../styles/Navbar.css';
// import {MdCameraAlt} from 'react-icons/md';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

function Navbar() {
    const [scrolled, setScrolled] = useState(false);
    const navRef = useRef(null);

    const handleScroll = (e) => {
        if (window.pageYOffset >= navRef.current.offsetTop) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    return (
        <header ref={navRef} className={`navbar ${scrolled ? "scrolled" : ""}`}>
            <div className="logo-container">
                <FontAwesomeIcon icon={faCamera} className="logo-camera"/>
                <strong><span className="logo-text">FlareShare</span></strong>
            </div>
        </header>
    )
}

export default Navbar;