import React, { useState, useEffect } from 'react';
import Post from './Post';
import '../styles/Posts.css';

function Posts() {
    // state variable for posts data
    const [posts, setPosts] = useState([]);

    // state variables for form control
    const [username, setUsername] = useState("");
    const [image, setImage] = useState("");
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const [link, setLink] = useState("");
    
    // state for select control
    const [postSelect, setSelected] = useState("id");

    // function to handle POST request to API endpoint
    async function handlePostReq(url, data) {
        console.log(data);
        await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }

    // handles adding a post to the KV namespace
    // function only runs if required field checks pass 
    function handleAddPost(e) {
        e.preventDefault();

        // data to push
        posts.push({
            title: title,
            username: username,
            image: image,
            link: link,
            likes: 0,
            content: content,
            id: posts.length
        });

        // push to workers api
        handlePostReq('https://worker.eswadswo.workers.dev/posts', posts);
    };

    // grabs data from worker api
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch('https://worker.eswadswo.workers.dev/posts');
                const data = await response.json();
                setPosts(data);
            }
            catch {
                setPosts([]);
            }
        }

        fetchPosts();
    }, []);

    return (
        <div className="Posts">
            <div id="sort-container">
                <label id="order-select-label" htmlFor="post-select">Sort By:   </label>
                <select value={postSelect} onChange={e => setSelected(e.target.value)} id="post-select">
                    <option value="id">Most Recent</option>
                    <option value="likes">Likes</option>
                </select>
            </div>

            <div className="create-post-container">
                <h1>Join The Conversation!</h1>
                <p>Add Your Own Post</p>
                {/* section for adding post */}
                <form onSubmit={handleAddPost}>
                    <label htmlFor="title">Post Title<span className="required-field">*</span></label>
                    <input  required value={title} onInput={e => setTitle(e.target.value)} type="text" id="title" />

                    <label htmlFor="username">username<span className="required-field">*</span></label>
                    <input required value={username} onInput={e => setUsername(e.target.value)} type="text" id="username" />

                    <label htmlFor="image">Media (images, gifs)</label>
                    <input value={image} onInput={e => setImage(e.target.value)} type="url" id="image" />

                    <label htmlFor="link">Links</label>
                    <input value={link} onInput={e => setLink(e.target.value)} type="url" id="link" />

                    <label htmlFor="content">Post Content<span className="required-field">*</span></label>
                    <textarea value={content} onInput={e => setContent(e.target.value)} required id="content"></textarea>
                    
                    <input type="submit" value="Create Post"/>
                </form>
            </div>

            <div>
                {posts.sort((a, b) => (a[postSelect] > b[postSelect]) ? -1 : 1).map(post => 
                    <Post 
                        title={post.title} 
                        image={post.image}
                        link={post.link}
                        username={post.username}
                        content={post.content}
                        likes={post.likes}
                        id={post.id}
                    />
                )}
            </div>
        </div>
    )
}

export default Posts;