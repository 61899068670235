import React, {useState, useEffect} from 'react';
import '../styles/Post.css';
import {FaRegHeart, FaHeart} from 'react-icons/fa';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHeart } from '@fortawesome/free-solid-svg-icons';
// import { faHeart } from '@fortawesome/free-solid-svg-icons';

// To implement like tracker, needed to create workaround with
// redundant get requests due to how KV storage was used to store 
// all posts
function Post(props) {

    const [posts, setPosts] = useState([]);
    const [clicked, setClicked] = useState(false);
    const [likeCount, updateLikeCount] = useState(0);

    // grabs data from worker api
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch('https://worker.eswadswo.workers.dev/posts');
                const data = await response.json();
                setPosts(data);
                updateLikeCount(data[props.id].likes);
            }
            catch {
                setPosts([]);
                updateLikeCount(0);
            }
        }

        fetchPosts();
    }, [props.id]);

    // run get request and then 
    async function handleLikeClicked(e) {
        const url = 'https://worker.eswadswo.workers.dev/posts';
        e.preventDefault();
        setClicked(true);
        updateLikeCount(likeCount + 1);

        // update like count for POST
        const updatedPosts = posts.map(post => {
            if (post.id === props.id) {
                const newPost = {
                    ...post,
                    likes: likeCount + 1
                };

                return newPost;
            }

            return post;
        });

        setPosts(updatedPosts);

        // post updated like count
        await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedPosts)
        });
    }

    return (
        <div className="post-container" id={props.id}>
            <h1>{props.title}</h1>
            {props.image ? <img src={`${props.image}`} className="post-img" alt=""></img> : <span></span>}
            <p className="post-username">Posted by: {props.username}</p>
            {props.link ? <a href={`${props.link}`}>{props.link}</a>: <span></span>}
            <p>{props.content}</p>
            {clicked ? 
                <FaHeart className="like-btn-filled"/> : 
                <FaRegHeart className="like-btn" onClick={handleLikeClicked}/>
            }
            <strong><div className="like-count">{likeCount}</div></strong>
        </div>
    )
}

export default Post;